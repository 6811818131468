import React from "react"
import { graphql } from "gatsby"
import Layout from "../../template/Layout"
import SEO from "../../template/seo";
import BreadCrumb from "../../template/BreadCrumb";
import SocialShare from "../_util/SocialShare";
import PageBuyLinks from "../_util/PageBuyLinks";
import style from "./Blog.module.scss";

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "Do MMMM, YYYY")
        slug
        title
        description
        featuredAlt
        featured {
          childImageSharp {
            resize(width: 1024) {
              src
              height
              width
          }}}
      }
    }
  }
`
type QueryProps = {
    data: any;
}

const BlogPost: React.FC<QueryProps> = ({ data }) => {

  const { frontmatter, html } = data.markdownRemark;
  const { title, description, date, slug, featured, featuredAlt  } = frontmatter;

  return (
    <>
    <SEO title={title + " | Prime Draft"} description={description} image={featured ? featured.childImageSharp.resize : undefined} imageAlt={featuredAlt} />
    <Layout contentStyle={style.layout} withBar={true}>
      <BreadCrumb links={[{ url: "/blog/", displayText: "Blog" }, { url: slug, displayText: title }]} />
      <section className={style.blog}>
        <h1>{title}</h1>
        <div>{description}</div>
        <p>{date}</p>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <hr />
        <SocialShare slug={slug} />
        <PageBuyLinks location="Blog" />
      </section>
    </Layout>
    </>
  );
}

export default BlogPost;

