import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import FacebookShareButton from "react-share/es/FacebookShareButton";
import TwitterShareButton from "react-share/es/TwitterShareButton";
import FacebookSvg from "../../assets/svg/facebook.inline.svg";
import TwitterSvg from "../../assets/svg/twitter.inline.svg";
import style from "./SocialShare.module.scss";

const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const TwitterIcon = () => (
    <div className={style.iconSize}>
        <TwitterSvg />
    </div>
);

const FacebookIcon = () => (
    <div className={style.iconSize}>
        <FacebookSvg />
    </div>
);

type SocialShareProps = {
    slug: any;
}

const SocialShare: React.FC<SocialShareProps> = ({ slug }) => {

    const { site } = useStaticQuery(query);

    const url = site.siteMetadata.siteUrl + slug;

    return (
        <div className={style.root}>
            <span>Share on</span>
            <FacebookShareButton
                url={url}
                quote={"Write your book, blog, or essay in less time with less stress using Prime Draft"}
                hashtag="#writing"
                children={<FacebookIcon />}
            />
            <TwitterShareButton 
                url={url}
                title={"Write your book, blog, or essay in less time with less stress using Prime Draft"}
                hashtags={["firstdraft", "writing"]}
                related={["@FirstDraftApp1"]}
                children={<TwitterIcon />}
            />
        </div>
    );

}

export default SocialShare;