import React from "react";
import { WinStoreLink, MacStoreLink, StoreClickLocation } from "./StoreLinks";
import style from "./PageBuyLinks.module.scss";

const PageBuyLinks: React.FC<{ location: StoreClickLocation }> = ({ location }) => {

    return (
        <div className={style.root}>
            <div>Get <strong>Prime Draft</strong> on <WinStoreLink location={location}>Windows</WinStoreLink></div>
            <div>Get <strong>Prime Draft</strong> on <MacStoreLink location={location}>Mac</MacStoreLink></div>
        </div>
    );
}

export default PageBuyLinks;