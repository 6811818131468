import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import React from "react";
import style from "./BreadCrumb.module.scss";

type ItemListElement = {
    "@type": "ListItem";
    position: number;
    name: string;
    item?: string;
}

type BreadCrumbList = {
    "@context": "https://schema.org";
    "@type": "BreadcrumbList";
    itemListElement: ItemListElement[];
}

type BreadCrumbLink = {
    displayText: string;
    url: string;
}

type BreadCrumbProps = {
    links: BreadCrumbLink[];
}

const createStructuredData = (links: BreadCrumbLink[]) => {

    const linksWithHome: BreadCrumbLink[] = [{ displayText: "Home", url: "/"}, ...links];

    const itemListElement: ItemListElement[] = linksWithHome.map((link, i) => ({
        "@type": "ListItem",
        position: i + 1,
        name: link.displayText,
        item: `https://first-draft.app${link.url === "/" ? "" : link.url}`,
    }));

    const breadCrumbList: BreadCrumbList = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: itemListElement,
    }

    return JSON.stringify(breadCrumbList);

}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ links }) => {

    return (
        <>
        <div className={style.root}>
            <ul className={style.navList}>
                <Link className={style.link} to="/">Home</Link>
                {links.map((l,i) => (
                    <div className={style.navOption} key={i}>
                        <div className={style.chevron} />
                        <Link className={style.link} to={l.url}>{l.displayText}</Link>
                    </div>
                ))}
            </ul>
        </div>
        <Helmet>
            <script type="application/ld+json">
                {createStructuredData(links)}
            </script>
        </Helmet>
        </>
    );

}

export default BreadCrumb;